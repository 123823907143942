/* ------------------------------------------------------------------ */
/*        HEADING DECLARATION
/* ------------------------------------------------------------------ */



* {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    background-repeat: no-repeat;
}
a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    // transition: transform 0.3s ease;

}

html {

    font-size: 1rem;
    color: $black;
    background-color: $red;
    font-family: 'ext125', sans-serif;
    letter-spacing: 0.8px;
    letter-spacing: 0.4;
    line-height: 1.2;
    font-weight: 100;
    width: 100vw;
    overflow-x: hidden;
    background-color: $red;

}


body {

    transition: opacity .5s ease;

}

// PREV PART

body {

    // background-color: $black;
    color: $white;
    // padding: 30px;
    font-family: 'ext200';
    font-size: 8px;
    line-height: 1.3;
    position: relative;

    @include sizeone {

        height: 100%;

    }

}

.desk {

    @include sizeone {

        display: none!important;

    }

}

.mob, .mob-flex {

    display: none!important;

}

.mob {

    @include sizeone {

        display: block!important;

    }
}

.mob-flex {

    display: none!important;

    @include sizeone {

        display: flex!important;

    }

}
h1 {

    font-family: 'round';
    font-size: 40px;
    // font-size: 70px;
    text-transform: uppercase;
    // transform: translateY(20px);

    // @include sizeone {

    //     font-size: 46px;
    //     transform: translateY(5px);
    //     line-height: 1;

    // }
}

h2 {

    font-family: 'round';
    text-transform: uppercase;
    font-size: 70px;
    transform: translateY(20px);

    @include sizeone {

        font-size: 46px;
        transform: translateY(5px);
        line-height: 1;

    }

}

h3 {

    font-size: 32px;
    font-family: 'ext125';
    text-transform: uppercase;

    @include sizeone {

        font-size: 21px;

    }

}

h4 {

    font-family: 'ext200';
    text-transform: uppercase;
    font-size: 40px;

    @include sizeone {

        font-size: 27px;

    }

}

h5 {

    font-family: 'ext125';
    text-transform: uppercase;
    // font-size: 20px;
    font-size: 16px;
    line-height: 1.2;

    @include sizeone {

        font-size: 16px;
        margin-top: 14px;

    }

    strong {

        font-family: 'ext200';

    }

}

h6 {

    font-family: 'ext125';
    text-transform: uppercase;
    font-size: 12px;

}

.chiff {

    font-family: 'chiff';

}

.hide {

    display: none!important;

}

