/* ------------------------------------------------------------------ */
/*        General HTML - BODY
/* ------------------------------------------------------------------ */
// * {
//     outline: none;
//     -ms-overflow-style: none;  // IE 10+
//     overflow: -moz-scrollbars-none;  // Firefox
//     &::-webkit-scrollbar {
//         display: none;
//     }
// }

/* ------------------------------------------------------------------ */
/*        HEADER
/* ------------------------------------------------------------------ */

header {

    .nav {

        position: fixed;
        top: 2px;
        color: white;
        width: 100%;
        padding-top: 1px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        mix-blend-mode: difference;
        z-index: 10000;
        padding-right: 20px;
        padding-left: 20px;
        // text-transform: uppercase;

        &_item {

            cursor: pointer;

        }

        .manifesto_button, .press_button, .index_button, .contact_button {

            z-index: 1000;
            text-transform: uppercase;
            // transform: scale(1,5.4) translate(0, -1.9px);
            transform: perspective(400px) rotateX(0);
            font-family: 'jet';
            font-size: 10px;
            padding-top: 13px;
            padding-bottom: 13px;
            position: relative;
            width: fit-content;

            @include sizeone {

                font-size: 12px;

            }

            .under {

                width: calc(100% - 16px);
                background-color: $black;
                height: 0.5px;
                position: absolute;
                left: 8px;
                transform: scaleX(0);
                transform-origin: 100% 0;

                transition: transform 0.3s ease;

            }

            &:hover .under {

                transform: scaleX(1);
                transform-origin: 0% 0;
        
            }
        }

        &_right_part, &_left_part {

            display: flex;
            z-index: 1000000;
            width: 150px;

            @include sizeone {

                width: fit-content;

            }

        }

        &_right_part {

            justify-content: flex-end;

        }

        .press_button, .index_button {

            margin-right: 20px;

        }
        
        .logo {

            font-family: "got";
            width: calc(100% - 260px);
            display: flex;
            left: 0px;
            justify-content: center;
            font-size: 31.7px;

            svg {

                width: 60vw;
                height: 100%;
                
                path {

                    fill: $black;

                }
            }

            @include sizetwo {

                width: 100%;
                position: fixed;
                top: 50px;
                left: 2px;

                svg {

                    width: 80%;

                }

            }

            @include sizeone {

                svg {
    
                    width: 85%;
    
                }
    
            }
        }
    }
}

.preloader {

    z-index: 10000000000;
    background-color: $black;
    width: 100vw;
    height: 100vh;

    &.mobile, &.tablet {

        height: calc(100 * var(--vh)); 

    }

    @include sizetwo {

        height: calc(100 * var(--vh)); 

    }
    

    .counter {

        font-family: 'jet', sans-serif;
        position: fixed;
        bottom: 15px;
        left: 20px;
        opacity: 0;

        @include sizeone {

            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: fit-content;

        }

    }

    .logo_intro {

        opacity: 0;
        font-family: "got";
        position: fixed;
        width: 100%;
        display: flex;
        left: 0px;
        top: calc(100% - 40px);
        justify-content: center;
        font-size: 31.7px;

        svg {

            width: 60vw;
            height: 100%;
            
            path {

                fill: $white;

            }
        }

        @include sizethree {

            top: calc(100vh - 30px);

        }

        @include sizetwo {

            top: calc((100 * var(--vh)) - 40px);
            left: 2px;

            svg {

                width: 80%;

            }

        }

        @include sizeone {

            svg {

                width: 85%;

            }

        }
    }
}

.tablet .mobile {

    .logo_intro {

        top: calc((100 * var(--vh)) - 40px);

    }
}

/* ------------------------------------------------------------------ */
/*        FOOTER
/* ------------------------------------------------------------------ */

.footer, footer {

}



/* ------------------------------------------------------------------ */
/*        Preloader
/* ------------------------------------------------------------------ */
// .banner {

//     position: fixed;
//     width: 400px;
//     height: 200px;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     background-color: #f8f8f8;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 30px;
//     text-align: center;
//     padding-bottom: 0px;
//     border-radius: 10px;
//     -webkit-box-shadow: 0px 1px 11px -3px #2e2e2e;
//     box-shadow: 0px 1px 11px -3px #2e2e2e;
//     z-index: 1000000;
//     display: none;

//     @include sizeone {

//         width: 88vw;

//     }

//     &.show {

//         display: flex;

//     }

//     a {

//         position: relative;

//         .under {

//             background-color: black;
//             width: 100%;
//             position: absolute;
//             bottom: -1px;
//             left: -1px;
//             height: 1px;
//             transform: scaleX(1);
//             transform-origin: 0% 0;
//             pointer-events: none;
//             transition: transform 0.3s $Power3EaseInOut,
//                         background-color 0.3s $Power3EaseInOut;

//         }

//         &:hover {

//             .under {

//                 transform: scaleX(0);
//                 transform-origin: 100% 0;

//             }

//         }

//     }

// }

/* ------------------------------------------------------------------ */
/*        404
/* ------------------------------------------------------------------ */

.error_page {

    height: calc(100vh - 313px);

    .error {

        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        a {

            text-transform: uppercase;
        }

    }

}