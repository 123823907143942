/* ------------------------------------------------------------------ */
/*        HOME2
/* ------------------------------------------------------------------ */

.home2 {

    height: 100vh;
    width: 100%;
    cursor: e-resize;

    .images_band {

        display: none;

        .image {

            position: absolute;
            bottom: 3px;
            left: 3px;

            height: calc(100vh - 70px);
            width: calc(80vw - 9px);
            object-fit: contain;
            object-position: left bottom;
            transition: height 0.5s ease;

            display: none;

            &.active {

                display: block;

            }

        }

    }

    .infos {

        position: fixed;
        top: 35px;
        left: 2px;
        display: flex;
        transform: scale(1,5.4) translate(0px, 6.5px);
        width: calc(100% - 4px);
        justify-content: space-between;
        text-transform: uppercase;
        opacity: 0;
        transform-origin: bottom;

        transition: transform 0.5s ease 0.4s;
        
            
    }

    .texte {

        opacity: 0;
        position: fixed;
        bottom: 3px;
        right: 3px;
        width: 20%;
        font-size: 9px;
        // transform: scale(2,1) translate(0px, 0px);
        // transform-origin: right bottom;

    }

    .open {

        .images_band, .texte {

            display: block;

        }

        .texte {

            opacity: 1;
            // transform: scale(1,1) translate(0px, 0px);

        }

        .infos {

            opacity: 1;
            transform: scale(1,3) translate(0px, 7px);

        }

    }

    .bio {

        display: none;

    }

}

.index {

    position: absolute;
    top: 42px;
    left: 3px;
    display: flex;
    flex-direction: column;
    width: 100%;

    &_line {

        display: flex;
        height: 30px;
        cursor: pointer;

        &_wrapper {

            display: flex;
            transform: translateY(30px);
            justify-content: space-between;
            text-transform: uppercase;
            width: calc(100% - 5px);
            opacity: 0;
            mix-blend-mode: difference;
            color: white;
            pointer-events: none;

        }

        &:hover {

            height: 64px;

            .index_line_wrapper {

                transform: scale(1, 10.5) translateY(26.2px) !important;
    
            }

        }

    }

    &_image {

        height: 100%;
        width: auto;
        object-fit: contain;

    }

}

.overview {

    .index {

        height: 180px;

    }

    .infos {

        top: calc(var(--indexHeight) + 35px);

    }

    .images_band {

        .image {

            height: calc(100vh - var(--indexHeight) - 35px - 32px);

        }

    }

}