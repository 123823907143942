/* ------------------------------------------------------------------ */
/*        FONT FACE DECLARATION
/* ------------------------------------------------------------------ */

@font-face {
    font-family: 'round';
    src: url(https://pandoragraessl.com/assets/fonts/ag-book-rounded-bold-condensed.otf);
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'bold';
    src: url(https://pandoragraessl.com/assets/fonts/SyndicatGrotesk-Medium.otf);
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'med';
    src: url(https://pandoragraessl.com/assets/fonts/SyndicatGrotesk-Bold.otf);
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'ext200';
    src: url(https://pandoragraessl.com/assets/fonts/BebasNeue-Regular.woff);
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'ext200';
    src: url(https://pandoragraessl.com/assets/fonts/BebasNeue-Regular.woff);
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'ext125';
    src: url(https://pandoragraessl.com/assets/fonts/MaisonJar-125.woff);
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'ext125';
    src: url(https://pandoragraessl.com/assets/fonts/MaisonJar-125Italic.woff);
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'chiff';
    src: url(https://pandoragraessl.com/assets/fonts/MaisonJar-Chiffre.woff);
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'got';
    src: url(https://pandoragraessl.com/assets/fonts/Plain_Germanica.ttf);
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'jet';
    src: url(https://pandoragraessl.com/assets/fonts/JetBrainsMono-Light.ttf);
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'cond';
    src: url(https://pandoragraessl.com/assets/fonts/MaisonJar-cond.woff);
    font-weight: 300;
    font-style: normal;
}
