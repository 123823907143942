/* ------------------------------------------------------------------ */
/*        HOME
/* ------------------------------------------------------------------ */
html {

    background-color: $black;

}

.home {

    transition: margin 0.3s ease;

    @include sizetwo {

        margin-top: 88px;

    }

    @include sizeone {

        margin-top: 11px;

    }

    .infos {

        display: flex;
        text-transform: uppercase;
        justify-content: space-between;
        height: fit-content;
        align-items: flex-end;
        padding-bottom: 0.5px;
        transition: height 0.3s ease;
        font-family: 'jet';
        font-size: 10px;
        letter-spacing: 0.8px;
    
        .title {
    
            transition: transform 0.3s ease;
            display: flex;
            align-items: center;
            position: relative;
    
        }

        .under {

            width: 100%;
            background-color: $white;
            height: 0.5px;
            position: absolute;
            left: 0px;
            bottom: -2px;
            transform: scaleX(0);
            transform-origin: 100% 0;

            transition: transform 0.3s ease;

        }

        .pagination {

            font-size: 10px;
            font-family: 'jet';
            padding-left: 20px;
        }
    
    }
    
    .wrapper_serie:hover {
    
        cursor: pointer;

    }

    .wrapper_serie {

        height: 0px;
        overflow: hidden;

        &:hover .under {
    
            transform: scaleX(1);
            transform-origin: 0% 0;
    
        }

    }

    .wrapper_home, .wrapper_second {

        height: fit-content;
        padding-bottom: 15px;
        @include sizeone {

            padding-bottom: 50px;

        }


    }

    .images_bands {

        // display: flex;
        // justify-content: center;
        height: 50vh;
        // flex-wrap: nowrap;
        width: max-content;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
        width: 100vw;
        cursor: pointer;

        &::-webkit-scrollbar {

            display: none;

        }
    
        // padding-bottom: 20px;

        .wrapper_image {
    
            height: 100%;
            width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
    
            &.center {
    
                justify-content: center;
    
            }

            @include sizetwo {

                justify-content: center;
                // padding: 0px 20px;

            }
                
        }
    
        img {
    
            display: inline;
            height: 100%;
            width: auto;
            object-fit: contain;
            object-position: bottom;
            padding: 33px;
            padding-bottom: 15px;
            padding-top: 43px;
            // padding-right: calc(10px / 2);
            // padding-left: calc(10px / 2);

            @include sizetwo {

                display: none;

                &:first-child {

                    display: block;
                    object-position: center;

                }

            }

            @include sizeone {

                padding-bottom: 25px;
                padding-top: 0px;

            }
        }

        img:first-child {

            padding-left: 0px;
            object-position: bottom left;

            @include sizetwo {

                width: auto;
                padding-right: 0px;
                object-position: inherit;

            }

        }

        img:last-child {

            padding-right: 0px;
            object-position: bottom right;

        }

        div[aria-label="Video Player"] {

            max-width: 100vw;
            height: 100%;
            width: 100%;
            object-fit: contain;
            padding: 33px;

        }

        video {

            width: 100%;
            height: 100%;
            object-fit: contain;

        }
    
    }

    .wrapper_serie:first-child {

        padding-top: 0px;

        .images_bands {

            height: calc(100vh - 36px);
            padding: 50px 0px;

            @include sizetwo {

                height: calc((100 * var(--vh)) - 49px);

            }
    
            .wrapper_image {
    
                justify-content: center;

                img {

                    @include sizetwo {

                        width: 100%!important;
                        padding-right: 10px!important;
                        padding-left: 10px!important;

                    }

                }
    
            }
    
        }

        .infos {

            @include sizeone {

                transform: translate(0px, 0px);

            }

                
        }

    }
}

.index_mode {

    margin-top: 42px;

    @include sizetwo {

        margin-top: 88px;

    }

    .wrapper_serie {

        height: fit-content;
        padding-bottom: 15px;

        @include sizeone {

            padding-bottom: 35px;

        }

        .infos {

            transform: translate(0px, 2.6px);
            font-family: 'jet';
            font-size: 11.7px;

            .pagination {

                font-size: 11.7px;
                transform: translate(5px, 0px);
            }

            .cat {

                display: block!important;

            }

            .title {

                position: relative;
                        
            }

            .under {

                width: 100%;
                background-color: $white;
                height: 0.5px;
                position: absolute;
                left: 0px;
                bottom: 0px;
                transform: scaleX(0);
                transform-origin: 100% 0;

                transition: transform 0.3s ease;

            }
    
        }

        &:hover .under {
    
            transform: scaleX(1);
            transform-origin: 0% 0;
    
        }

    }

    .wrapper_second {

        height: 0px;
        padding-bottom: 0px;

        .images_bands {

            height: 0px;

        }

    }

    .images_bands {

        justify-content: space-between;
        height: 90px;

        @include sizeone {

            height: 0px;

        }

        .wrapper_image {

            @include sizetwo {

                justify-content: space-between;

            }

            img {

                display: block;

                &.first {

                    padding-left: 0px;

                }

                &.last {

                    padding-right: 0px;

                }

            }
        }
    }

    .wrapper_serie:hover .images_bands {

        height: 170px!important;

    }

    .wrapper_serie:hover .wrapper_image {

        justify-content: space-around;

    }

    .wrapper_image img, .wrapper_image div[aria-label="Video Player"] {

        padding: 20px;
        padding-bottom: 0px;

        @include sizeone {

            padding-bottom: 0px;

        }

    }

    .wrapper_serie:first-child {

        height: fit-content;
        padding: 0px;
        padding-bottom: 25px;

        .images_bands {

            justify-content: space-between;
            height: 90px;
            padding: 0px;

            .wrapper_image {

                justify-content: space-between;

            }
    
        }

    }

}

.preload {

    height: 100vh;
    overflow: hidden;
    
    .nav {

        opacity: 0;
        pointer-events: none;

    }

}

.projet {

    height: 100vh;

    .infos {

        display: flex;
        text-transform: uppercase;
        justify-content: space-between;
        height: 11px;
        padding-bottom: 0.5px;
        transition: height 0.3s ease;
        font-family: 'cond';
        font-size: 9px;
        letter-spacing: -0.4px;
        padding-top: 35px;
        

        position: fixed;
        bottom: 8px;
        width: 100%;

        z-index: 1000;
        mix-blend-mode: difference;
        color: $black;

    }

    .description {

        padding: 60px;
        font-size: 11.8px;
        line-height: 1.65;
        height: calc(100vh - 60px);
        z-index: -1;
        font-family: 'jet', sans-serif;

        columns: 2;
        moz-column-gap: 40px;
        grid-column-gap: 40px;
        -webkit-column-gap: 40px;
        column-gap: 40px;

        p {

            margin-bottom: 30px;

            a {

                z-index: 10000;
                position: relative;

            }

        }

        @include sizetwo {

            columns: 1;
            padding-top: 110px;
            width: 100%;

        }

        @include sizeone {

            font-size: 15px;
            padding: 28px;
            padding-top: 97.5px;
            line-height: 1.9;
            overflow: scroll;
            z-index: 10000;
            overflow-x: hidden;
            height: unset;
            padding-bottom: 100px;

        }

    }

    .images {

        // width: 100%;
        // height: 100vh;
        // overflow: scroll;
        position: absolute;
        height: 100vh;
        width: 100%;
        overflow: scroll;
        top: 0px;

        .wrapper_images {
        
            padding-top: 50vh;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
    
            img {
    
                max-height: 80%;
                height: calc(100vh - 40px);
                max-width: 80vw;
                object-fit: contain;
                margin: 32px 32px;
                width: 100vw;

                &.loaded {

                    with: 100%;

                }

            }

            div[aria-label="Video Player"] {

                width: auto;
                height: calc(100vh - 40px);
                padding: 33px;

                video {

                    width: auto;
                    height: 100%;

                }
            }
        }
    }
}

.manifesto {

    .video_container {

        height: 100vh;
        width: 100vw;
        overflow: hidden;

        @include sizeone {

            width: calc(100 * var(--vh));
            height: 100vw;
            object-fit: cover;
            object-position: center;
            position: absolute;
            top: 0px;
            transform: rotate(90deg);
            transform-origin: left top;
            left: 100%;

        }

    }
    
    video, img {

        width: 100%;
        height: 100%;
        object-fit: cover;

    }

    .text {

        padding: 60px;
        font-size: 11.8px;
        line-height: 1.65;
        width: 70%;
        z-index: -1;
        font-family: 'jet', sans-serif;
        height: fit-content;

        p {

            padding-bottom: 20px

        }

        @include sizeone {

            width: 100%;
            padding-top: 100vh;

        }

    }

}

.press {

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include sizeone {

        margin-top: 23vh;

    }

    &_item {

        width: 80%;
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {

            display: flex;
            justify-content: center;
            flex-direction: column;

        }

        @include sizeone {

            height: 50vh;

        }

        img {

            width: 100%;
            object-fit: contain;

        }

        .title_link {

            font-size: 11.8px;
            line-height: 1.65;
            z-index: -1;
            font-family: 'jet', sans-serif;
            text-align: center;
            padding-top: 30px;

        }

    }

    .title_page {

        position: fixed;
        bottom: -2px;
        width: 100%;
        display: flex; 
        justify-content: space-between;
        font-family: 'cond';
        font-size: 9px;
        letter-spacing: -0.4px;
        padding-top: 35px;

    }

}

.contact {

    height: 100vh;
    display: flex;
    justify-content: center;

    @include sizeone {

        display: block;

    }


    .text_bio {

        padding: 60px;
        font-size: 11.8px;
        line-height: 1.65;
        width: 70%;
        z-index: 1;
        font-family: 'jet', sans-serif;
        height: fit-content;

        p {

            padding-bottom: 20px

        }

        // columns: 2;
        // moz-column-gap: 40px;
        // grid-column-gap: 40px;
        // -webkit-column-gap: 40px;
        // column-gap: 40px;

        a {

            text-decoration: underline;

            .under {

                width: calc(100% - 16px);
                background-color: $black;
                height: 0.5px;
                position: absolute;
                left: 8px;
                transform: scaleX(0);
                transform-origin: 100% 0;

                transition: transform 0.3s ease;

            }

            &:hover .under {

                transform: scaleX(1);
                transform-origin: 0% 0;
        
            }

        }
        
        @include sizetwo {

            columns: 1;
            padding-top: 110px;
            width: 100%;

        }

        @include sizeone {

            font-size: 15px;
            padding: 28px;
            padding-top: 97.5px;
            line-height: 1.9;
            overflow: scroll;
            z-index: 10000;
            overflow-x: hidden;

        }

        .bio_para {

            margin-bottom: 20px;

            &:last-child {

                margin-bottom: 0px;
    
            }

        }

    }

    .title_page {

        position: fixed;
        bottom: -2px;
        width: 100%;
        display: flex; 
        justify-content: space-between;
        font-family: "jet";
        font-size: 10px;
        color: white;
        mix-blend-mode: difference;
        z-index: 100;
        padding: 5px 20px;



        a {

            padding-top: 13px;
            padding-bottom: 13px;
            position: relative;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;

        }

    }

    .portrait {

        height: 150px;
        width: 150px;
        position: fixed;
        bottom: 85px;
        right: 5vh;

    
        img {

            width: 100%;
            height: 100%;
            object-fit: cover;

        }

        @include sizetwo {

            display: none;

        }

        @include sizeone {

            display: block;
            position: static;
            float: right;
            margin: 0px 30px;
            margin-bottom: 100px;


        }
    }
}

.vjs-error {

    display: none;
}





